<template lang="html">
  <div class="property">
    <div class="box">
      <div class="title">市场管理员联系电话</div>
      <div class="list" v-for="item in listData" :key="item.user_id">
        <div class="left">
          <div>
            {{ item.name }}
          </div>
          <div class="mobile">
            {{ item.mobile }}
          </div>
        </div>
        <div class="right" @click="$common.callPhone(item.mobile)">
        <img src="../../assets/image/mobile.png" alt="">
          <!-- <van-icon name="phone" /> -->
        </div>
      </div>
      <div class="empty" v-if="listData.length <=0">暂无管理员电话~</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'property',
  data() {
    return {
      listData: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$require.HttpPost('/h5/user/userList', { pageNum: 1, pageSize: 100, property_status: 10 }).then(res => {
        if (res.data && res.data.list) {
          let arr = [];
          // for (let i in res.data.list) {
          //   if (res.data.list[i].management.role_worker_id.indexOf('1100000000') != -1) {
          //     arr.push(res.data.list[i]);
          //   }
          // }
          this.listData = res.data.list;
          console.log(arr);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.property {
  height: calc(100vh - 378px);
  background: url("../../assets/image/lianxiwuyeBk.png") no-repeat;
  background-size: 100% 336px;
  padding-top: 378px;
  background-color: #F9F9F9;
  .box {
    width:100%;
    margin: 0 auto;
    padding: 0 32px;
    box-sizing: border-box;
    .title {
      font-size: 32px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #000000;
      line-height: 19px;
      margin-bottom: 50px;
    }
    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 32px;
      width: 100%;
      height: 176px;
      background: #ffffff;
      border-radius: 10px;
      margin-bottom: 32px;
      padding: 0 32px;
      box-sizing: border-box;
      .left {
        color: #000000;
        .mobile{
          margin-top: 20px;
          color: rgba(0, 0, 0, 0.3);
        }
      }
      .right {
        color: #81b51d;
        height: 76px;
        width: 76px;
        border-left: 5px dashed rgba(0, 0, 0, 0.1);
        padding-right: 25px;
        img{
           height: 100%;
           width: 100%;
           margin:0  25px;
        }
        span {
          margin-right: 20px;
        }
      }
    }
  }
  .empty{
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #529100;
      text-align: center;
      margin-top: 80px;
  }
}

</style>
